import React from "react";
import Layout from "../components/layout/Layout";
import { Helmet } from "react-helmet";

export default function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy | Baseline</title>
        <meta
          name="description"
          content="Baseline is an efficient and cost effective resource planning tool that builds and manages the optimal teams for your projects."
        />
        <meta
          name="keywords"
          content="Saas, Cheap, Time Saving ,Team, Team Management,Mental Health,Best Team Management Tool, Best Project Management Tool,Easy to use Team Management Tool,Easy to use Project Management Tool,Monitoring profitability,Ways to improve profitability,Time management,How to improve mental health,How to build a successful team,Staffing tool,Utilization tool,HR tool or HR Software,Resource allocation,People first team creation,Hybrid workforce,Visualize department utilization,Collaborative team building,Hard and Soft skill data talent search,User friendly team management,Future workforce,Creating happy and profitable teams,How can I see burnout in my team,"
        />
        <link rel="canonical" href="https://baselineteams.com" />
      </Helmet>
      <Layout>
        <div className="bg-white py-16 px-4 text-black lg:px-64">
          <h1 className="justify-center text-center text-3xl font-bold mt-8 mb-16">
            Baseline Privacy Policy{" "}
          </h1>
          <div className="text-base my-4">Effective date: January 2021</div>
          <div className="text-base my-4">
            This Privacy Policy describes how Baseline collects, uses and
            discloses information and what choices you have with respect to the
            information. When we refer to “Baseline”, we mean the Baseline
            entity that acts as the controller or processor of your information,
            as explained in more detail in the “Identifying the Data Controller
            and Processor” section below.
          </div>
          <div className="my-8">
            <h2 className="text-xl font-bold my-8">Table of Contents</h2>
            <ul className="list-outside list-disc my-4 text-primary-blue ml-4">
              <li className="my-2">
                <a href="#applicability-of-privacy-policy">
                  Applicability of this Privacy Policy
                </a>
              </li>
              <li className="my-2">
                <a href="#information-we-collect-and-receive">
                  Information we collect and receive
                </a>
              </li>
              <li className="my-2">
                <a href="#how-we-use-information">How we use information</a>
              </li>
              <li className="my-2">
                <a href="#data-retention">Data retention</a>
              </li>
              <li className="my-2">
                <a href="#how-we-share-and-disclose-information">
                  How we share and disclose information
                </a>
              </li>
              <li className="my-2">
                <a href="#security">Security</a>
              </li>
              <li className="my-2">
                <a href="#age-limitations">Age limitations</a>
              </li>
              <li className="my-2">
                <a href="#changes-to-this-privacy-policy">
                  Changes to this Privacy Policy
                </a>
              </li>
              <li className="my-2">
                <a href="#data-protection-officer">Data Protection Officer</a>
              </li>
              <li className="my-2">
                <a href="#identifying-the-data-controller-and-processor">
                  Identifying the Data Controller and Processor
                </a>
              </li>
              <li className="my-2">
                <a href="#your-rights">Your rights</a>
              </li>
              <li className="my-2">
                <a href="#data-protection-authority">
                  Data Protection Authority
                </a>
              </li>
              <li className="my-2">
                <a href="#contacting-baseline">Contacting Baseline</a>
              </li>
            </ul>
          </div>
          <div id="applicability-of-privacy-policy" className="my-8">
            <h2 className="text-xl font-bold my-8">
              Applicability of this Privacy Policy
            </h2>
            <p>
              This Privacy Policy applies to Baseline’s online workplace
              productivity tools and platform, including the associated Baseline
              mobile and desktop applications (collectively the “Services”),
              baselineteams.com and other Baseline websites (collectively the
              “Websites”) and other interactions (e.g. customer service
              enquiries, user conferences, etc.) you may have with Baseline. If
              you do not agree with the terms, do not access or use the
              Services, Websites or any other aspect of Baseline’s business.
              <br />
              <br />
              This Privacy Policy does not apply to any third-party applications
              or software that integrate with the Services through the Baseline
              platform (“Third-Party Services”), or any other third-party
              products, services or businesses. In addition, a separate
              agreement governs delivery, access and use of the Services (the
              “Customer Agreement”), including the processing of any messages,
              files or other content submitted through Services accounts
              (collectively, “Customer Data”). The organisation (e.g., your
              employer or another entity or person) that entered into the
              Customer Agreement (“Customer”) controls its instance of the
              Services (its “Workspace”) and any associated Customer Data. If
              you have any questions about specific Workspace settings and
              privacy practices, please contact the Customer whose Workspace you
              use. If you have received an invitation to join a Workspace but
              have not yet created an account, you should request assistance
              from the Customer that sent the invitation.
            </p>
          </div>
          <div id="information-we-collect-and-receive" className="my-8">
            <h2 className="text-xl font-bold my-8">
              Information we collect and receive
            </h2>
            <p className="my-4">
              Baseline may collect and receive Customer Data and other
              information and data (“Other Information”) in a variety of ways:
            </p>
            <ul className="list-outside list-disc my-4 ml-4">
              <li className="my-4">
                <span className="font-bold">Customer Data. </span>Customers or
                individuals granted access to a Workspace by a Customer
                (“Authorised Users”) routinely submit Customer Data to Baseline
                when using the Services. When you create an account or otherwise
                use the Services, we collect information such as your name,
                email address, password, role within your Workspace and an
                optional profile photo.
              </li>
              <li className="my-4">
                <span className="font-bold">Other Information.</span> Baseline
                also collects, generates and/or receives Other Information:
                <ol className="list-outside list-decimal my-4">
                  <li className="my-4">
                    Workspace and account information. To create or update a
                    Workspace account, you or your Customer (e.g. your employer)
                    supply Baseline with an email address, phone number,
                    password, domain and/or similar account details. In
                    addition, Customers that purchase a paid version of the
                    Services provide Baseline (or its payment processors) with
                    billing details such as credit card information, banking
                    information and/or a billing address. We use third-party
                    payment providers to process payments on the Services. We
                    may receive information associated with your payment
                    information, such as billing address and transaction
                    information, but we do not directly store payment
                    information on the Services.
                  </li>
                  <li className="my-4">
                    Usage information.
                    <ul className="list-outside list-disc ml-4">
                      <li className="my-4">
                        Services metadata. When an Authorised User interacts
                        with the Services, metadata is generated that provides
                        additional context about the way Authorised Users work.
                        For example, Baseline logs the Workspaces, channels,
                        people, features, content and links you view or interact
                        with, the types of files shared and what Third-Party
                        Services are used (if any).
                      </li>
                      <li className="my-4">
                        Log data. As with most websites and technology services
                        delivered over the Internet, our servers automatically
                        collect information when you access or use our Websites
                        or Services and record it in log files. This log data
                        may include the Internet Protocol (IP) address, the
                        address of the web page visited before using the Website
                        or Services, browser type and settings, the date and
                        time the Services were used, information about browser
                        configuration and plugins, language preferences and
                        cookie data.
                      </li>
                      <li className="my-4">
                        Device information. Baseline collects information about
                        devices accessing the Services, including type of
                        device, what operating system is used, device settings,
                        application IDs, unique device identifiers and crash
                        data. Whether we collect some or all of this Other
                        Information often depends on the type of device used and
                        its settings.
                      </li>
                      <li className="my-4">
                        Location information. We receive information from you,
                        your Customer and other third-parties that helps us
                        approximate your location. We may, for example, use a
                        business address submitted by your employer or an IP
                        address received from your browser or device to
                        determine approximate location. Baseline may also
                        collect location information from devices in accordance
                        with the consent process provided by your device.
                      </li>
                    </ul>
                  </li>
                  <li className="my-4">
                    Cookie information. Baseline uses a variety of cookies and
                    similar technologies in our Websites and Services to help us
                    collect Other Information.
                  </li>
                  <li className="my-4">
                    Third-Party Services. A Customer can choose to permit or
                    restrict Third-Party Services for its Workspace. Typically,
                    Third-Party Services are software that integrate with our
                    Services, and a Customer can permit its Authorised Users to
                    enable and disable these integrations for its Workspace.
                    Baseline may also develop and offer Baseline applications
                    that connect the Services with a Third-Party Service. Once
                    enabled, the provider of a Third Party Service may share
                    certain information with Baseline. For example, if a cloud
                    storage application is enabled to permit files to be
                    imported to a Workspace, we may receive the user name and
                    email address of Authorised Users, along with additional
                    information that the application has elected to make
                    available to Baseline to facilitate the integration.
                    Authorised Users should check the privacy settings and
                    notices in these Third-Party Services to understand what
                    data may be disclosed to Baseline. When a Third-Party
                    Service is enabled, Baseline is authorised to connect and
                    access Other Information made available to Baseline in
                    accordance with our agreement with the Third-Party Provider
                    and any permission(s) granted by the Customer (including by
                    its Authorised User(s)). We do not, however, receive or
                    store passwords for any of these Third-Party Services when
                    connecting them to the Services.
                  </li>
                  <li className="my-4">
                    Contact information. In accordance with the consent process
                    provided by your device, any contact information that an
                    Authorised User chooses to import (such as an address book
                    from a device) is collected when using the Services.
                  </li>
                  <li className="my-4">
                    Third-party data. Baseline may receive data about
                    organisations, industries, lists of companies that are
                    customers, Website visitors, marketing campaigns and other
                    matters related to our business from parent corporation(s),
                    affiliates and subsidiaries, our partners or others that we
                    use to make our own information better or more useful. This
                    data may be combined with Other Information we collect and
                    might include aggregate-level data, such as which IP
                    addresses correspond to postcodes or countries. Or it might
                    be more specific: for example, how well an online marketing
                    or email campaign performed.
                  </li>
                  <li className="my-4">
                    Additional information provided to Baseline. We also receive
                    Other Information when submitted to our Websites or in other
                    ways, such as if you participate in a focus group, contest,
                    activity or event, apply for a job, enrol in a certification
                    programme or other educational programme hosted by Baseline
                    or a vendor, request support, interact with our social media
                    accounts or otherwise communicate with Baseline.
                  </li>
                </ol>
              </li>
            </ul>
            <p className="my-4">
              Generally, no one is under a statutory or contractual obligation
              to provide any Customer Data or Other Information (collectively
              “Information”). However, certain Information is collected
              automatically and if some Information, such as Workspace setup
              details, is not provided, we may be unable to provide the
              Services.
            </p>
          </div>
          <div id="how-we-use-information" className="my-8">
            <h2 className="text-xl font-bold my-8">How we use information</h2>
            <p>
              Customer Data will be used by Baseline in accordance with the
              Customer’s instructions, including any applicable terms in the
              Customer Agreement and the Customer’s use of Services
              functionality, and as required by applicable law. Baseline is a
              Processor of Customer Data and the Customer is the Controller. The
              Customer may, for example, use the Services to grant and remove
              access to a Workspace, assign roles and configure settings,
              access, modify, export, share and remove Customer Data and
              otherwise apply its policies to the Services.
              <br />
              <br />
              Baseline uses Other Information in furtherance of our legitimate
              interests in operating our Services, Websites and business. More
              specifically, Baseline uses Other Information:
            </p>
            <ul className="list-outside list-disc my-4 ml-4">
              <li className="my-4">
                <span className="font-bold">
                  To provide, update, maintain and protect our Services,
                  Websites and business.{" "}
                </span>{" "}
                This includes use of Other Information to support delivery of
                the Services under a Customer Agreement, prevent or address
                service errors, security or technical issues, analyse and
                monitor usage, trends and other activities or at an Authorised
                User’s request.
              </li>
              <li className="my-4">
                <span className="font-bold">
                  As required by applicable law, legal process or regulation.
                </span>
              </li>
              <li className="my-4">
                <span className="font-bold">
                  To communicate with you by responding to your requests,
                  comments and questions.{" "}
                </span>
                If you contact us, we may use your Other Information to respond.
              </li>
              <li className="my-4">
                <span className="font-bold">
                  To develop and provide search, learning and productivity tools
                  and additional features.{" "}
                </span>
                Baseline tries to make the Services as useful as possible for
                specific Workspaces and Authorised Users. For example, we may
                improve search functionality by using Other Information to help
                determine and rank the relevance of content, channels or
                expertise to an Authorised User, make Services or Third-Party
                Service suggestions based on historical use and predictive
                models, identify organisational trends and insights, customise a
                Services experience or create new productivity features and
                products.
              </li>
              <li className="my-4">
                <span className="font-bold">
                  To send emails and other communications.
                </span>
                We may send you service, technical and other administrative
                emails, messages and other types of communications. We may also
                contact you to inform you about changes in our Services, our
                Services offerings and important Services-related notices, such
                as security and fraud notices. These communications are
                considered part of the Services and you may not opt out of them.
                In addition, we sometimes send emails about new product
                features, promotional communications or other news about
                Baseline. These are marketing messages so you can control
                whether you receive them. If you have additional questions about
                a message you have received from Baseline please get in touch
                through the contact mechanisms described below.
              </li>
              <li className="my-4">
                <span className="font-bold">
                  For billing, account management and other administrative
                  matters.
                </span>
                Baseline may need to contact you for invoicing, account
                management and similar reasons and we use account data to
                administer accounts and keep track of billing and payments.
              </li>
              <li className="my-4">
                <span className="font-bold">
                  To investigate and help prevent security issues and abuse.
                </span>
              </li>
              <li className="my-4">
                <span className="font-bold">
                  Serve Administrative purposes.{" "}
                </span>
                Such as, but not limited to pursuing legitimate interests, such
                as marketing research, research and development. Along with
                measuring interest and engagement in our Services, including
                analysis to develop new products and services and improve the
                Services for you the Customer.
              </li>
            </ul>
            <p className="my-4">
              If Information is aggregated or de-identified so that it is no
              longer reasonably associated with an identified or identifiable
              natural person, Baseline may use it for any business purpose. To
              the extent Information is associated with an identified or
              identifiable natural person and is protected as personal data
              under applicable data protection law, it is referred to in this
              Privacy Policy as
              <span className="font-bold">“Personal Data”.</span>
            </p>
          </div>
          <div id="data-retention" className="my-8">
            <h2 className="text-xl font-bold my-8">Data retention</h2>
            <p className="my-8">
              Baseline will retain Customer Data in accordance with a Customer’s
              instructions, including any applicable terms in the Customer
              Agreement and the Customer’s use of Services functionality, and as
              required by applicable law. Depending on the Services
              subscription, the Customer may be able to customise their
              retention settings and apply those customised settings at the
              workspace level, channel level or other level. The Customer may
              also apply different settings to messages, files or other types of
              Customer Data. The deletion of Customer Data and other use of the
              Services by the Customer may result in the deletion and/or
              de-identification of certain associated Other Information.
              Baseline may retain Other Information pertaining to you for as
              long as necessary for the purposes described in this Privacy
              Policy. This may include keeping your Other Information after you
              have deactivated your account for the period of time needed for
              Baseline to pursue legitimate business interests, conduct audits,
              comply with (and demonstrate compliance with) legal obligations,
              resolve disputes and enforce our agreements.
            </p>
          </div>
          <div id="how-we-share-and-disclose-information" className="my-8">
            <h2 className="text-xl font-bold my-8">
              How we share and disclose information
            </h2>
            <p className="my-8">
              This section describes how Baseline may share and disclose
              Information. Customers determine their own policies and practices
              for the sharing and disclosure of Information. Baseline does not
              control how they or any other third parties choose to share or
              disclose Information.
            </p>
            <ul className="list-outside list-disc my-4 ml-4">
              <li className="my-4">
                <span className="font-bold">The Customer’s instructions. </span>
                Baseline will solely share and disclose Customer Data in
                accordance with a Customer’s instructions, including any
                applicable terms in the Customer Agreement and the Customer’s
                use of Services functionality and in compliance with applicable
                law and legal process.
              </li>
              <li className="my-4">
                <span className="font-bold">Displaying the Services. </span>When
                an Authorised User submits Other Information, it may be
                displayed to other Authorised Users in the same or connected
                Workspaces. For example, an Authorised User’s email address may
                be displayed with their Workspace profile.
              </li>
              <li className="my-4">
                <span className="font-bold">Collaborating with others. </span>
                The Services will provide different ways for Authorised Users
                working in independent Workspaces to collaborate, for instance
                through email interoperability. Other Information, such as an
                Authorised User’s profile Information, may be shared, subject to
                the policies and practices of the other Workspace(s).
              </li>
              <li className="my-4">
                <span className="font-bold">Customer access. </span>Owners,
                administrators, Authorised Users and other Customer
                representatives and personnel may be able to access, modify, or
                restrict access to Other Information. This may include, for
                example, your employer using Service features to export logs of
                Workspace activity, or accessing or modifying your profile
                details. For information about your Workspace settings, please
                see under your Workspace’s profile under Team Settings.
              </li>
              <li className="my-4">
                <span className="font-bold">
                  Third-Party service providers and partners.{" "}
                </span>
                We may engage Third-Party companies or individuals as service
                providers or business partners to process Other Information and
                support our business. These third parties may, for example,
                provide virtual computing and storage services or we may share
                business information to develop strategic partnerships with
                Third-Party Service providers to support our common customers.
                Additional information about the subprocessors we use to support
                delivery of our Services is set out at Baseline subprocessors.
              </li>
              <li className="my-4">
                <span className="font-bold">Third-Party Services. </span> The
                Customer may enable or permit Authorised Users to enable
                Third-Party Services. We require each Third-Party Service to
                disclose all permissions for information access in the Services,
                but we do not guarantee that they do so. When enabled, Baseline
                may share Other Information with Third-Party Services.
                Third-Party Services are not owned or controlled by Baseline and
                third parties that have been granted access to Other Information
                may have their own policies and practices for its collection,
                use and sharing. Please check the permissions, privacy settings
                and notices for these Third-Party Services or contact the
                provider for any questions.
              </li>
              <li className="my-4">
                <span className="font-bold">Corporate affiliates. </span>
                Baseline may share Other Information with its corporate
                affiliates, parents and/or subsidiaries.
              </li>
              <li className="my-4">
                <span className="font-bold">
                  During a change to Baseline’s business.{" "}
                </span>
                If Baseline engages in a merger, acquisition, bankruptcy,
                dissolution, reorganisation, sale of some or all of Baseline’s
                assets or stock, financing, public offering of securities,
                acquisition of all or a portion of our business, a similar
                transaction or proceeding or steps in contemplation of such
                activities, some or all Other Information may be shared or
                transferred, subject to standard confidentiality arrangements.
              </li>
              <li className="my-4">
                <span className="font-bold">
                  Aggregated or deidentified Data.{" "}
                </span>
                We may disclose or use aggregated or deidentified Other
                Information for any purpose. For example, we may share
                aggregated or deidentified Other Information with prospects or
                partners for business or research purposes, such as telling a
                prospective Baseline customer the average amount of time spent
                within a typical Workspace.
              </li>
              <li className="my-4">
                <span className="font-bold">To comply with laws. </span>If we
                receive a request for information, we may disclose Other
                Information if we reasonably believe disclosure is in accordance
                with or required by any applicable law, regulation or legal
                process.
              </li>
              <li className="my-4">
                <span className="font-bold">
                  To enforce our rights, prevent fraud and for safety.{" "}
                </span>
                To protect and defend the rights, property or safety of Baseline
                or third parties, including enforcing contracts or policies, or
                in connection with investigating and preventing fraud or
                security issues.
              </li>
              <li className="my-4">
                <span className="font-bold">With consent. </span>Baseline may
                share Other Information with third parties when we have consent
                to do so.
              </li>
            </ul>
          </div>
          <div id="security" className="my-8">
            <h2 className="text-xl font-bold my-8">Security</h2>
            <p className="my-4">
              Baseline takes security of data very seriously. Baseline works
              hard to protect Other Information you provide from loss, misuse
              and unauthorised access or disclosure. These steps take into
              account the sensitivity of the Other Information we collect,
              process and store and the current state of technology. Given the
              nature of communications and information processing technology,
              Baseline cannot guarantee that Information during transmission
              through the Internet or while stored on our systems or otherwise
              in our care will be absolutely safe from intrusion by others. When
              you click a link to a third-party site, you will be leaving our
              site and we don’t control or endorse what is on third-party sites.
            </p>
          </div>
          <div id="age-limitations" className="my-8">
            <h2 className="text-xl font-bold my-8">Age limitations</h2>
            <p className="my-4">
              To the extent prohibited by applicable law, Baseline does not
              allow use of our Services and Websites by anyone younger than 16
              years old. If you learn that anyone younger than 16 has unlawfully
              provided us with personal data, please contact us and we will take
              steps to delete such information.
            </p>
          </div>
          <div id="changes-to-this-privacy-policy" className="my-8">
            <h2 className="text-xl font-bold my-8">
              Changes to this Privacy Policy
            </h2>
            <p className="my-4">
              Baseline may change this Privacy Policy from time to time. Laws,
              regulations and industry standards evolve, which may make those
              changes necessary, or we may make changes to our services or
              business. We will post the changes to this page and encourage you
              to review our Privacy Policy to stay informed. If we make changes
              that materially alter your privacy rights, Baseline will provide
              additional notice, such as via email or through the Services. If
              you disagree with the changes to this Privacy Policy, you should
              deactivate your Services account. Contact the Customer if you wish
              to request the removal of Personal Data under their control.
            </p>
          </div>
          <div id="data-protection-officer" className="my-8">
            <h2 className="text-xl font-bold my-8">Data Protection Officer</h2>
            <p className="my-4">
              To communicate with our Data Protection Officer, please email
              legal@baselineteams.com.
            </p>
          </div>
          <div
            id="identifying-the-data-controller-and-processor"
            className="my-8"
          >
            <h2 className="text-xl font-bold my-8">
              Identifying the Data Controller and Processor
            </h2>
            <p className="my-4">
              Data protection law in certain jurisdictions differentiates
              between the “Controller” and “Processor” of information. In
              general, the Customer is the Controller of Customer Data. Baseline
              is the Processor of Customer Data and the Controller of Other
              Information.
            </p>
          </div>
          <div id="your-rights" className="my-8">
            <h2 className="text-xl font-bold my-8">Your rights</h2>
            <p className="my-4">
              Individuals located in certain countries, including the European
              Economic Area and the United Kingdom, have certain statutory
              rights in relation to their personal data. Subject to any
              exemptions provided by law, you may have the right to request
              access to Information, as well as to seek to update, delete or
              correct this Information. You can usually do this using the
              settings and tools provided in your Services account. If you
              cannot use the settings and tools, contact the Customer who
              controls your workspace for additional access and assistance.
              <br />
              <br />
              To the extent that Baseline’s processing of your Personal Data is
              subject to the General Data Protection Regulation (or applicable
              laws covering the processing of Personal Data in the United
              Kingdom), Baseline relies on its legitimate interests, described
              above, to process your data. Baseline may also process Other
              Information that constitutes your Personal Data for direct
              marketing purposes and you have a right to object to Baseline’s
              use of your Personal Data for this purpose at any time.
            </p>
          </div>
          <div id="data-processing-authority" className="my-8">
            <h2 className="text-xl font-bold my-8">
              Data Protection Authority
            </h2>
            <p className="my-4">
              Data protection law in certain jurisdictions differentiates
              between the “Controller” and “Processor” of information. In
              general, the Customer is the Controller of Customer Data. Baseline
              is the Processor of Customer Data and the Controller of Other
              Information.
            </p>
          </div>
          <div id="contacting-baseline" className="my-8">
            <h2 className="text-xl font-bold my-8">Contacting Baseline</h2>
            <p className="my-4">
              Please also feel free to contact Baseline if you have any
              questions about this Privacy Policy or Baseline’s practices or if
              you are seeking to exercise any of your statutory rights. Baseline
              will respond within a reasonable timeframe. You can contact us at
              legal@baselineteams.com or at our postal address below:
              <br />
              <br />
              N. A. K. <br />
              5-28-12-102 Daizawa, Setagaya-ku, Tokyo 155-0032 <br />
              hello@nakstudios.com
            </p>
          </div>
        </div>
      </Layout>
    </>
  );
}
